@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom animation class */
@layer components {
  .animate-unfold {
    animation: unfold 2s ease-out forwards;
  }
   .animate-slideDown {
    animation: slideDown 2s ease-out forwards;
  }
}
@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  .underline-animation {
    position: relative;
    display: inline-block;
  }

  .underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: currentColor; /* Utilise la couleur actuelle du texte */
    bottom: -2px; /* Ajuste si nécessaire pour que la ligne soit juste sous le texte */
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
  }

  .underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@layer utilities {
  .bg-gradient-animation {
    position: relative;
    overflow: hidden;
  }

  .bg-gradient-animation::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: linear-gradient(to right, transparent 50%, #2a2448 50%);
    z-index: -1;
    transition: transform 0.5s ease-out;
    transform: translateX(-100%);
  }

  .bg-gradient-animation:hover::before {
    transform: translateX(0);
  }
}



/* Animation pour chaque texte */
@keyframes slide-up {
    0%, 25% {
        opacity: 0;
        transform: translateY(1em);
    }
    5%, 20% {
        opacity: 1;
        transform: translateY(0);
    }
    30%, 70% {
        opacity: 1;
        transform: translateY(-1em); /* Ajustez pour la hauteur souhaitée */
    }
    35%, 100% {
        opacity: 0;
        transform: translateY(1em);
    }
}

.content {
  width: 1600px;
}



.content__container {
    position: relative;
    height: 34px;
    overflow: hidden;
    width: 100%;
}
.content__container__text {
    position: absolute;
    margin-left: 41.5%;
    margin-top: 0px;
}
.content__container__list {
    position: absolute;
    list-style-type: none;
    margin-left: 56%;
    animation-name: trans;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    margin-top: 0px;
    display: inline;
}
@keyframes opacity{
  0%,50%{opacity:0;color:#1D1F20;}
}
@keyframes trans {
  0%, 14.66%{transform:translateY(0%)}
  16.66%, 31.32% {transform:translateY(-25%)}
  33.32%,47.98% {transform:translateY(-50%)}
  49.98%,64.64% {transform:translateY(-75%)}
  66.64%,81.30% {transform:translateY(-50%)}
  83.30%,97.96% {transform:translateY(-25%)}
}